import React from "react";
import { ParallaxProvider } from "react-scroll-parallax";
import { ThemeProvider } from "styled-components";

interface ThemeProps {
  children?: React.ReactNode;
}

const breakpoints = ["768px", "1024px"];

const theme = {
  breakpoints,
};

const Providers: React.FC<ThemeProps> = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <ParallaxProvider>{children}</ParallaxProvider>
    </ThemeProvider>
  );
};

export default Providers;
