import classnames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { Container } from "./StyledSystem/Container";
import { Title } from "./StyledSystem/Title";
interface IStoryProps {}

//@ts-ignore
const innerHeight = window.innerHeight;

const Story: React.FunctionComponent<IStoryProps> = (props) => {
  const { t } = useTranslation();

  const isMobile = useMediaQuery({ query: "(min-width: 769px" });

  const images = [];
  const datas = Array.from({ length: 28 }, (_, i) => images.push(i + 1));
  const [visibleImagesMap, setVisibleImagesMap] = React.useState(
    datas.reduce((map: any, image) => {
      map[image] = false;
      return map;
    }, {})
  );

  React.useLayoutEffect(() => {
    const handleScroll = () => {
      //@ts-ignore
      const scrollTop = document.documentElement.scrollTop;
      const viewportHeight = innerHeight / 3;

      const newVisibleImagesMap = datas.reduce((map: any, image) => {
        map[image] = scrollTop >= image * viewportHeight;
        return map;
      }, {});

      setVisibleImagesMap(newVisibleImagesMap);
    };
    //@ts-ignore
    window.addEventListener("scroll", handleScroll);
    handleScroll();
    //@ts-ignore
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <Container
        id="STORY"
        // backgroundImage={[
        //   "url('/images/movie-web/web01.jpg')",
        //   "url('/images/story-mobile-bg.png')",
        // ]}
        // backgroundRepeat={'no-repeat'}
        // backgroundSize={'cover'}
        // backgroundPosition={'center'}
        width="100%"
        height="calc(2900vh / 3)"
        position="relative"
      >
        <StickyWrap>
          <FrameWrap index={1} isMobile={isMobile}>
            {datas.map((image, i) => (
              <ImageWrap
                className={classnames("image", `image_${image}`, {
                  image_visible: visibleImagesMap[image],
                })}
                key={i}
                isMobile={isMobile}
                index={image}
              />
            ))}
            <Container
              m={[0, "auto"]}
              p={[0, 16]}
              maxWidth={1240}
              display="flex"
              flexDirection="column"
              alignItems="center"
              //   justifyContent='center'
            >
              <Container
                // marginTop={240}
                // position={'sticky'}
                // top={'50%'}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                zIndex={3000}
              >
                {/* <Container
                display={['none', 'flex']}
                flexDirection={'column'}
                alignItems={'center'}
                justifyContent={'center'}
                zIndex={8000}
              >
                <div>
                  <Container
                    position={'sticky'}
                    backgroundColor={'red'}
                    top={200}
                    width={'100%'}
                    height={200}
                    zIndex={8001}
                  >
                    xprtmxm
                  </Container>
                  <img src='/images/face.png' alt='face' />
                  <img src='/images/story-logo.png' alt='logo' />
                </div>
                <div>
                  <Container
                    position={'sticky'}
                    backgroundColor={'blue'}
                    width={'100%'}
                    top={200}
                    height={200}
                    zIndex={8001}
                  />
                  <img src='/images/face.png' alt='face' />
                  <img src='/images/story-logo.png' alt='logo' />
                </div>
                <div>
                  <Container
                    position={'sticky'}
                    backgroundColor={'yellow'}
                    width={'100%'}
                    top={200}
                    height={200}
                    zIndex={8001}
                  />
                  <img src='/images/face.png' alt='face' />
                  <img src='/images/story-logo.png' alt='logo' />
                </div>
              </Container> */}
              </Container>
            </Container>
          </FrameWrap>
        </StickyWrap>

        <ScrollContainer>
          <ScrollSequence>
            {(
              t("story", { returnObjects: true }) as Array<{
                copy: string;
              }>
            ).map(({ copy }, i) => (
              <Speak key={i}>
                {i === 0 && (
                  <>
                    <Container
                      display={["block", "none"]}
                      zIndex={13}
                      marginBottom={100}
                    >
                      <img src="/images/story-mobile-logo.png" alt="logo" />
                    </Container>
                    <Container
                      display={["none", "block"]}
                      zIndex={13}
                      marginBottom={100}
                    >
                      <Container
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <img src="/images/face.png" alt="face" />

                        <img src="/images/story-logo.png" alt="logo" />
                      </Container>
                    </Container>
                  </>
                )}
                <Title
                  textAlign="center"
                  fontSize={[16, 25]}
                  // width={['', 700]}
                  paddingLeft={10}
                  paddingRight={10}
                >
                  {copy}
                </Title>
              </Speak>
            ))}
          </ScrollSequence>
        </ScrollContainer>
      </Container>
    </>
  );
};

export default Story;

interface IImage {
  isMobile?: boolean;
  index?: number;
}

const ImageWrap = styled.div<IImage>`
  &.image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    opacity: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  &.image_1 {
    z-index: 1;
    /* background-image: url('/images/movie-web/web01.jpg'); */
    background-image: ${({ isMobile, index }) =>
      isMobile
        ? `url('/images/movie-web/web${index}.jpg')`
        : `url('/images/movie-mobile/mobile${index}.jpg')`};
  }

  &.image_2 {
    z-index: 2;
    background-image: ${({ isMobile, index }) =>
      isMobile
        ? `url('/images/movie-web/web${index}.jpg')`
        : `url('/images/movie-mobile/mobile${index}.jpg')`};
  }

  &.image_3 {
    z-index: 3;
    background-image: ${({ isMobile, index }) =>
      isMobile
        ? `url('/images/movie-web/web${index}.jpg')`
        : `url('/images/movie-mobile/mobile${index}.jpg')`};
  }
  &.image_4 {
    z-index: 4;
    background-image: ${({ isMobile, index }) =>
      isMobile
        ? `url('/images/movie-web/web${index}.jpg')`
        : `url('/images/movie-mobile/mobile${index}.jpg')`};
  }
  &.image_5 {
    z-index: 5;
    background-image: ${({ isMobile, index }) =>
      isMobile
        ? `url('/images/movie-web/web${index}.jpg')`
        : `url('/images/movie-mobile/mobile${index}.jpg')`};
  }
  &.image_6 {
    z-index: 6;
    background-image: ${({ isMobile, index }) =>
      isMobile
        ? `url('/images/movie-web/web${index}.jpg')`
        : `url('/images/movie-mobile/mobile${index}.jpg')`};
  }
  &.image_7 {
    z-index: 7;
    background-image: ${({ isMobile, index }) =>
      isMobile
        ? `url('/images/movie-web/web${index}.jpg')`
        : `url('/images/movie-mobile/mobile${index}.jpg')`};
  }
  &.image_8 {
    z-index: 8;
    background-image: ${({ isMobile, index }) =>
      isMobile
        ? `url('/images/movie-web/web${index}.jpg')`
        : `url('/images/movie-mobile/mobile${index}.jpg')`};
  }
  &.image_9 {
    z-index: 9;
    background-image: ${({ isMobile, index }) =>
      isMobile
        ? `url('/images/movie-web/web${index}.jpg')`
        : `url('/images/movie-mobile/mobile${index}.jpg')`};
  }
  &.image_10 {
    z-index: 10;
    background-image: ${({ isMobile, index }) =>
      isMobile
        ? `url('/images/movie-web/web${index}.jpg')`
        : `url('/images/movie-mobile/mobile${index}.jpg')`};
  }
  &.image_11 {
    z-index: 11;
    background-image: ${({ isMobile, index }) =>
      isMobile
        ? `url('/images/movie-web/web${index}.jpg')`
        : `url('/images/movie-mobile/mobile${index}.jpg')`};
  }
  &.image_12 {
    z-index: 12;
    background-image: ${({ isMobile, index }) =>
      isMobile
        ? `url('/images/movie-web/web${index}.jpg')`
        : `url('/images/movie-mobile/mobile${index}.jpg')`};
  }
  &.image_13 {
    z-index: 13;
    background-image: ${({ isMobile, index }) =>
      isMobile
        ? `url('/images/movie-web/web${index}.jpg')`
        : `url('/images/movie-mobile/mobile${index}.jpg')`};
  }
  &.image_14 {
    z-index: 14;
    background-image: ${({ isMobile, index }) =>
      isMobile
        ? `url('/images/movie-web/web${index}.jpg')`
        : `url('/images/movie-mobile/mobile${index}.jpg')`};
  }
  &.image_15 {
    z-index: 15;
    background-image: ${({ isMobile, index }) =>
      isMobile
        ? `url('/images/movie-web/web${index}.jpg')`
        : `url('/images/movie-mobile/mobile${index}.jpg')`};
  }
  &.image_16 {
    z-index: 16;
    background-image: ${({ isMobile, index }) =>
      isMobile
        ? `url('/images/movie-web/web${index}.jpg')`
        : `url('/images/movie-mobile/mobile${index}.jpg')`};
  }
  &.image_17 {
    z-index: 17;
    background-image: ${({ isMobile, index }) =>
      isMobile
        ? `url('/images/movie-web/web${index}.jpg')`
        : `url('/images/movie-mobile/mobile${index}.jpg')`};
  }
  &.image_18 {
    z-index: 18;
    background-image: ${({ isMobile, index }) =>
      isMobile
        ? `url('/images/movie-web/web${index}.jpg')`
        : `url('/images/movie-mobile/mobile${index}.jpg')`};
  }
  &.image_19 {
    z-index: 19;
    background-image: ${({ isMobile, index }) =>
      isMobile
        ? `url('/images/movie-web/web${index}.jpg')`
        : `url('/images/movie-mobile/mobile${index}.jpg')`};
  }
  &.image_20 {
    z-index: 20;
    background-image: ${({ isMobile, index }) =>
      isMobile
        ? `url('/images/movie-web/web${index}.jpg')`
        : `url('/images/movie-mobile/mobile${index}.jpg')`};
  }
  &.image_21 {
    z-index: 21;
    background-image: ${({ isMobile, index }) =>
      isMobile
        ? `url('/images/movie-web/web${index}.jpg')`
        : `url('/images/movie-mobile/mobile${index}.jpg')`};
  }
  &.image_22 {
    z-index: 22;
    background-image: ${({ isMobile, index }) =>
      isMobile
        ? `url('/images/movie-web/web${index}.jpg')`
        : `url('/images/movie-mobile/mobile${index}.jpg')`};
  }
  &.image_23 {
    z-index: 23;
    background-image: ${({ isMobile, index }) =>
      isMobile
        ? `url('/images/movie-web/web${index}.jpg')`
        : `url('/images/movie-mobile/mobile${index}.jpg')`};
  }
  &.image_24 {
    z-index: 24;
    background-image: ${({ isMobile, index }) =>
      isMobile
        ? `url('/images/movie-web/web${index}.jpg')`
        : `url('/images/movie-mobile/mobile${index}.jpg')`};
  }
  &.image_25 {
    z-index: 25;
    background-image: ${({ isMobile, index }) =>
      isMobile
        ? `url('/images/movie-web/web${index}.jpg')`
        : `url('/images/movie-mobile/mobile${index}.jpg')`};
  }
  &.image_26 {
    z-index: 26;
    background-image: ${({ isMobile, index }) =>
      isMobile
        ? `url('/images/movie-web/web${index}.jpg')`
        : `url('/images/movie-mobile/mobile${index}.jpg')`};
  }
  &.image_27 {
    z-index: 27;
    background-image: ${({ isMobile, index }) =>
      isMobile
        ? `url('/images/movie-web/web${index}.jpg')`
        : `url('/images/movie-mobile/mobile${index}.jpg')`};
  }
  &.image_28 {
    z-index: 28;
    background-image: ${({ isMobile, index }) =>
      isMobile
        ? `url('/images/movie-web/web${index}.jpg')`
        : `url('/images/movie-mobile/mobile${index}.jpg')`};
  }
  &.image_29 {
    z-index: 29;
    background-image: ${({ isMobile, index }) =>
      isMobile
        ? `url('/images/movie-web/web${index}.jpg')`
        : `url('/images/movie-mobile/mobile${index}.jpg')`};
  }

  &.image_visible {
    opacity: 1;
  }
`;

const StickyWrap = styled.div`
  position: sticky;
  top: 0;
  height: 100vh;
`;

const FrameWrap = styled.div<IImage>`
  z-index: 1;
  position: relative;
  height: 100%;
  width: 100%;
  background-image: ${({ isMobile, index }) =>
    isMobile
      ? `url('/images/movie-web/web${index}.jpg')`
      : `url('/images/movie-mobile/mobile${index}.jpg')`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

const ScrollContainer = styled(Container)`
  height: 100vh;
`;

const ScrollSequence = styled.section`
  position: sticky;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 7000;
  height: 100vh;
`;

const Speak = styled.div`
  margin: 230vh 0;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 30;
  &:first-child {
    margin-top: -50vh;
  }
`;
