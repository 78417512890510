import axios from "axios";
import styled from "styled-components";
import React, { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

// recoil
import { useRecoilState, useRecoilValue } from "recoil";
import { gridAtom } from "../../atoms/grid";
import { rarityChkboxAtom } from "../../atoms/rarityChkbox";
import foxDataAtom from "../../atoms/foxData";
import searchKeywordAtom from "../../atoms/searchKeyword";
import filterAscDescAtom from "../../atoms/filterAscDesc";

// pages
const FoxItem = React.lazy(() => import("./FoxItem"));

interface IArrayAttribute {
  rarity: string;
  trait_type: string;
  value: string;
}

interface IArray {
  attributes: IArrayAttribute[];
  description: string;
  img: string;
  name: string;
  number: string;
  owner: string;
  rarity: string;
  type: string;
}

const Loader = <div></div>;

const FoxList: React.FC = () => {
  const [foxes, setFoxes] = useRecoilState(foxDataAtom);
  const [array, setArray] = useState<IArray[]>([]);
  const currentGrid = useRecoilValue(gridAtom);
  const searchKeyword = useRecoilValue(searchKeywordAtom);
  const filterAscDesc = useRecoilValue(filterAscDescAtom);
  const rarityChkbox = useRecoilValue(rarityChkboxAtom);

  const filteredFoxes = React.useMemo(() => {
    let cFoxes = [...foxes];

    // 검색
    if (searchKeyword !== "") {
      cFoxes = cFoxes.filter(
        (fox: any) => fox.name.indexOf(searchKeyword) !== -1
      );
    }

    // 레어리티 필터
    cFoxes = cFoxes.filter((item) => {
      if (rarityChkbox.normal && item.rarity === "Normal") {
        return true;
      }
      if (rarityChkbox.middle && item.rarity === "Middle") {
        return true;
      }
      if (rarityChkbox.rare && item.rarity === "Rare") {
        return true;
      }
      if (rarityChkbox.superRare && item.rarity === "Super rare") {
        return true;
      }
      if (rarityChkbox.specialEd && item.rarity === "Special Edition") {
        return true;
      }
      if (
        !rarityChkbox.normal &&
        !rarityChkbox.middle &&
        !rarityChkbox.rare &&
        !rarityChkbox.superRare &&
        !rarityChkbox.specialEd
      ) {
        return true;
      }
    });

    // 정렬
    cFoxes = cFoxes.sort((a, b) =>
      //@ts-ignore
      filterAscDesc === "ASC" ? a.number - b.number : b.number - a.number
    );

    return cFoxes;
  }, [foxes, searchKeyword, filterAscDesc, rarityChkbox]);

  const allFoxLists = async () => {
    const { data } = await axios.get(
      "https://badfoxclub.s3.ap-northeast-2.amazonaws.com/json/foxes.json"
    );

    const foxDataArray: any[] = [];

    for (let i = 0, len = currentGrid === 1 ? 10 : 15; i < len; i++) {
      foxDataArray.push(data[i]);
    }
    setArray(foxDataArray);
    setFoxes(data);
  };

  const fetchMoreData = () => {
    const newfoxArray: any = [];

    setTimeout(() => {
      for (
        let i = array.length,
          len = currentGrid === 1 ? array.length + 25 : array.length + 55;
        i < len;
        i++
      ) {
        if (filteredFoxes[i]) {
          newfoxArray.push(filteredFoxes[i]);
        }
      }

      setArray([...array, ...newfoxArray]);
    }, 500);
  };

  // 처음에만 10,009개 가져옴
  useEffect(() => {
    allFoxLists();
  }, []);

  // 필터 변경하면 필터링 된 filteredFoxes에서 그리드 별로 10, 15개만 처음에 보여줌
  useEffect(() => {
    const newfoxArray: any = [];

    for (let i = 0, len = currentGrid === 1 ? 30 : 70; i < len; i++) {
      if (filteredFoxes[i]) {
        newfoxArray.push(filteredFoxes[i]);
      }
    }

    setArray(newfoxArray);
  }, [filteredFoxes]);

  return (
    <InfiniteScroll
      dataLength={array.length}
      //This is important field to render the next data
      next={fetchMoreData}
      hasMore={true}
      loader={Loader}
    >
      <GridBox currentGrid={currentGrid}>
        {array.map((fox: any, i: number) => (
          <FoxItem key={`fox_${i}`} {...fox} />
        ))}
      </GridBox>
    </InfiniteScroll>
  );
};

interface IGridBox {
  currentGrid: number;
}

const GridBox = styled.div<IGridBox>`
  display: grid;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;
  grid-template-columns: repeat(
    ${({ currentGrid }) => (currentGrid === 1 ? 1 : 2)},
    1fr
  );
  gap: 12px 16px;
  @media (min-width: 768px) {
    grid-template-columns: repeat(
      ${({ currentGrid }) => (currentGrid === 1 ? 5 : 10)},
      1fr
    );

    gap: ${({ currentGrid }) => (currentGrid === 1 ? "" : "10px")};
  }
`;

export default FoxList;
