import * as React from "react";
import { Button } from "./StyledSystem/Button";
import { Container } from "./StyledSystem/Container";
import { Desc } from "./StyledSystem/Description";
import { Title } from "./StyledSystem/Title";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface IIntroProps {}

const Intro: React.FunctionComponent<IIntroProps> = (props) => {
  const { t } = useTranslation();

  const onClickWl = React.useCallback(() => {
    window.open("https://t10rfh48csx.typeform.com/to/GDg3EmRo");
  }, []);

  return (
    <>
      <Container
        id="HOME"
        backgroundImage={[
          "url('/images/intro-mobile-bg.png')",
          "url('/images/intro-bg.jpg')",
        ]}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        backgroundPosition="center"
        width="100%"
        height="100vh"
      >
        <Container
          m={[0, "auto"]}
          p={16}
          maxWidth={1240}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          height="100%"
        >
          {/* <Container display={["block", "none"]}>
            <img
              src="/images/intro-mobile-logo.png"
              alt="logo"
              style={{ width: 310 }}
            />
          </Container> */}
          <Title
            color="white"
            mt={["80", "100"]}
            fontSize={[24, 35]}
            lineHeight={1.3}
            fontWeight={["800", "bold"]}
            textAlign="left"
          >
            {t("intro.main")}
          </Title>
          <Copy
            color="white"
            fontSize={[11, 16]}
            fontWeight="bold"
            lineHeight={["1.8", "1.5"]}
            paddingBottom={["8px", "0"]}
            mt={3}
            textAlign="left"
          >
            {t("intro.sub")}
          </Copy>

          <Copy
            color="white"
            pt={[3]}
            fontSize={["10px", 11]}
            textAlign="left"
          ></Copy>

          <Container display="flex" alignItems="center">
            <WlBtn
              height={40}
              boxShadow="0 0 10.9px 5.1px #ff712b"
              pl={["40px", "70px"]}
              pr={["40px", "70px"]}
              fontSize={["12px", "16px"]}
              backgroundColor="rgba(0,0,0,0.7)"
              borderRadius={9}
              opacity={0.85}
              border="none"
              color="#ff5a00"
              marginTop={[40, 54]}
              lineHeight={1.4}
              letterSpacing={-0.1}
              fontWeight={700}
              onClick={onClickWl}
            >
              {t("intro.button")}
            </WlBtn>
          </Container>
        </Container>
      </Container>
      {/* </Container> */}
    </>
  );
};

export default Intro;

const WlBtn = styled(Button)`
  cursor: pointer;
  margin: 0;
`;
const Copy = styled(Desc)`
  white-space: pre-line;
`;

const InputItem = styled.input`
  height: 40px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  width: 13rem;
  border: none;
  font-size: 12px;
  padding-left: 20px;
  background-color: rgba(255, 255, 255, 0.7);
  @media (min-width: 768px) {
    width: 22rem;
  }
`;
