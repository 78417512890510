import * as React from "react";
import i18n from "../i18n";
import styled from "styled-components";
import Hamburger from "hamburger-react";
import { Link } from "react-router-dom";
import { Link as Portal } from "react-scroll";
import { changeLanguage } from "i18next";

// styled-system
import { Desc } from "./StyledSystem/Description";
import { Title } from "./StyledSystem/Title";
import { Button } from "./StyledSystem/Button";
import { Container } from "./StyledSystem/Container";

// icons
import { AiOutlineClose } from "react-icons/ai";

interface IHeaderProps {}

const menu = [
  { name: "HOME", url: "/" },
  { name: "STORY", url: "/story" },
  { name: "ABOUT", url: "/about" },
  { name: "AGIT MEMBERS", url: "/members" },
  { name: "FAQ", url: "/FAQ" },
];
const sns = ["TWITTER"];

const Header: React.FunctionComponent<IHeaderProps> = (props) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [cyclubOpen, setCyclubOpen] = React.useState<boolean>(false);
  const [currentLanguage, setCurrentLanguage] = React.useState<"ko" | "en">(
    i18n.language as "ko" | "en"
  );

  const onClickOpen = React.useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const onClickClose = React.useCallback(() => {
    setIsOpen(false);
  }, []);

  const onOpenCyclub = React.useCallback(() => {
    setCyclubOpen(true);
  }, []);

  const onCloseCyclub = React.useCallback(() => {
    setCyclubOpen(false);
  }, []);

  const [klayWallet, setKlayWallet] = React.useState<string>("");

  const connectKlay = React.useCallback(async () => {
    //@ts-ignore
    const { klaytn } = window;
    if (klaytn !== undefined) {
      const accounts = await klaytn.enable();
      setKlayWallet(accounts[0]);
    }
  }, [setKlayWallet]);

  // const onClickConnect = React.useCallback(() => connectKlay(), []);

  const [modalIsOpen, setModalIsOpen] = React.useState<boolean>(false);
  const [menuData, setMenuData] = React.useState<string>("");

  const openModal = (item: string) => () => {
    if (item === "AGIT MEMBERS") {
      setModalIsOpen(true);
    } else {
      setModalIsOpen(false);
      setMenuData(item);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const openLink = (item: string) => () => {
    if (item === "DISCORD") {
      window.open("https://discord.gg/auZvqnQ4");
    }
    if (item === "TWITTER") {
      window.open("https://twitter.com/BADFOXCLUB");
    }
  };

  return (
    <>
      <HeaderWrap>
        <Container
          m={[0, "auto"]}
          pl={10}
          pr={[10, 35]}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          height="100%"
          maxWidth={1240}
        >
          <LogoLink href="/">
            <LogoImg src="/images/logo.png" alt="logo" />
          </LogoLink>
          <Container display={["none", "flex"]} alignItems="center">
            {menu.map((item, i) => (
              <>
                {item.url ? (
                  <Link to={item.url}>
                    <MenuTitle
                      textAlign="center"
                      key={i}
                      color="white"
                      fontSize={15}
                      marginRight={[37, 10, 37]}
                      // onClick={openModal(item)}
                    >
                      {item.name}
                    </MenuTitle>
                  </Link>
                ) : (
                  <Portal to={item.name} smooth={true} duration={1000}>
                    <MenuTitle
                      textAlign="center"
                      key={i}
                      color="white"
                      fontSize={15}
                      marginRight={[37, 10, 37]}
                      // onClick={openModal(item)}
                    >
                      {item.name}
                    </MenuTitle>
                  </Portal>
                )}
              </>
            ))}
          </Container>

          <Container display={["flex"]} alignItems={["center"]}>
            <Container display={["none", "flex"]}>
              {sns
                .map((item, i) => (
                  <SocialBtn
                    height={25}
                    marginRight={[5, 10]}
                    key={i}
                    onClick={openLink(item)}
                  >
                    <img
                      src={`/images/${item}.png`}
                      alt="social"
                      style={{ height: "100%" }}
                    />
                  </SocialBtn>
                ))
                .reverse()}
            </Container>

            <Container
              color="white"
              marginRight={10}
              display={["none", "block"]}
            >
              |
            </Container>

            <Container
              marginRight={[0, 10]}
              marginTop={[1, 1]}
              style={{ cursor: "pointer" }}
              onClick={() => {
                const l = currentLanguage === "ko" ? "en" : "ko";
                changeLanguage(l);
                setCurrentLanguage(l);
              }}
            >
              {currentLanguage === "ko" ? (
                <img
                  src="/images/language.png"
                  alt="language"
                  style={{ height: 15 }}
                />
              ) : (
                <div style={{ color: "white" }}>KR</div>
              )}
            </Container>
            {/* {klayWallet ? (
              <WalletBtn
                width={115}
                height={24}
                backgroundColor={'#000000'}
                borderRadius={10}
                border={'none'}
                color={'#ff5a00'}
                boxShadow={'0 0 8.3px 2.2px rgba(255, 113, 43, 0.58);'}
                fontSize={10}
                display={['none', 'block']}
                onClick={onClickConnect}
              >
                {getShortAddress(klayWallet)}
              </WalletBtn>
            ) : ( */}

            <WalletBtn
              width={115}
              height={24}
              backgroundColor="#000000"
              borderRadius={10}
              border="none"
              color="#ff5a00"
              boxShadow="0 0 8.3px 2.2px rgba(255, 113, 43, 0.58);"
              fontSize={10}
              display={["none", "block"]}
              onClick={onOpenCyclub}
              // onClick={openModal}
            >
              CONNECT WALLET
            </WalletBtn>
            {cyclubOpen && (
              <QRmodal>
                <QRdimmed onClick={onCloseCyclub} />
                <CyclubGroup>
                  <CyclubArea>
                    <WalletSection>
                      <div
                        style={{
                          position: "absolute",
                          top: "13px",
                          right: "13px",
                          cursor: "pointer",
                        }}
                        onClick={onCloseCyclub}
                      >
                        <AiOutlineClose size="25px" color="#fff" />
                      </div>

                      <Title
                        color="#fff"
                        fontSize="20px"
                        fontWeight="bold"
                        pb="5px"
                      >
                        싸이클럽 월렛 QR 연결
                      </Title>
                      <QRArea></QRArea>
                      <Container
                        display="flex"
                        alignContent="center"
                        justifyContent="center"
                        fontSize="15px"
                      >
                        <Desc fontWeight="bold" color="#fff">
                          남은시간 :&nbsp;
                        </Desc>
                        <Desc fontWeight="bold" color="#8ac88e">
                          29 : 58
                        </Desc>
                      </Container>
                      <Desc
                        color="#fff"
                        fontWeight="bold"
                        fontSize="14px"
                        pb="10px"
                      >
                        QR 코드 리더기 또는 싸이클럽 앱을 통해 <br />
                        QR 코드를 스캔해주세요
                      </Desc>
                    </WalletSection>
                    <CyclubWrap>
                      <p>혹시 싸이클럽 앱이 없으신가요?</p>
                      <CyclubBtn
                        onClick={() =>
                          window.open(
                            "https://play.google.com/store/apps/details?id=io.cyclub.app"
                          )
                        }
                      >
                        싸이클럽 앱 설치하기
                      </CyclubBtn>
                    </CyclubWrap>
                  </CyclubArea>
                </CyclubGroup>
              </QRmodal>
            )}
            <Container
              onClick={onClickOpen}
              display={["block", "none"]}
              marginTop={0.8}
              // width={16}
            >
              <Hamburger toggled={isOpen} color="#ff5a00" size={19} />
            </Container>
          </Container>
        </Container>
        {isOpen && (
          <MenuWrap pt={40} pl="17%">
            <Container>
              {menu.map((item, i) => (
                <Link to={item.url}>
                  <MobileTitle
                    key={i}
                    color="white"
                    fontSize={12}
                    marginBottom={30}
                    onClick={onClickClose}
                  >
                    {item.name}
                  </MobileTitle>
                </Link>
              ))}

              <Container>
                <Title color="#787878" fontSize={12} marginBottom={30}>
                  SNS
                </Title>
                {sns.map((item, index) => (
                  <Container
                    key={index}
                    display="flex"
                    alignItems="center"
                    marginBottom={30}
                  >
                    <SocialBtn height={25} marginRight={15}>
                      <img
                        src={`/images/${item}.png`}
                        alt="discord"
                        style={{ height: "100%" }}
                      />
                    </SocialBtn>
                    <SocialTitle
                      color="white"
                      fontSize={12}
                      onClick={openLink(item)}
                    >
                      {item}
                    </SocialTitle>
                  </Container>
                ))}
              </Container>
            </Container>
          </MenuWrap>
        )}
      </HeaderWrap>
      {/* <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={modalStyles}
        ariaHideApp={false}
      >
        <CloseBtn position="absolute" left="90%" top="5%" onClick={closeModal}>
          <AiOutlineClose size={20} />
        </CloseBtn>
        <Container
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          position="relative"
        >
          <Title fontSize={18}>Coming soon</Title>
        </Container>
      </Modal> */}
    </>
  );
};

export default Header;
const MobileTitle = styled(Title)`
  cursor: pointer;
`;
const LogoLink = styled.a`
  cursor: pointer;
`;
const CloseBtn = styled(Container)`
  cursor: pointer;
`;

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    // marginRight: '-50%',

    transform: "translate(-50%, -50%)",
    zIndex: 7002,
    width: 300,
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: 7003,
  },
};

interface IOpen {
  isOpen?: boolean;
}

const HeaderWrap = styled(Container)`
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 70px;
  position: fixed;
  z-index: 10000;
`;

const MenuWrap = styled(Container)<IOpen>`
  position: absolute;
  width: 100%;
  height: calc(100vh - 50px);
  background-color: #0b0b0b;
  z-index: 7000;
`;

const MenuTitle = styled(Title)`
  &:first-of-type {
    margin-left: 10px;
  }

  &:hover {
    color: #ff5a00;
  }

  cursor: pointer;
`;

const WalletBtn = styled(Button)`
  &:hover {
    opacity: 0.7;
  }

  cursor: pointer;
`;

const SocialBtn = styled(Container)`
  &:hover {
    opacity: 0.7;
  }

  cursor: pointer;
`;

const SocialTitle = styled(Title)`
  &:hover {
    opacity: 0.7;
  }

  cursor: pointer;
`;

const QRmodal = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
`;

const QRdimmed = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
`;

const CyclubGroup = styled.div`
  position: absolute;
  inset: 50% auto auto 50%;
  transform: translate(-50%, -50%);
  outline: none;
`;

const CyclubArea = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 480px;
  border-radius: 16px;
  box-shadow: rgb(0 0 0 / 25%) 0px 4px 4px 0px;
  background-color: #ffffff;
  box-sizing: border-box;
  text-align: center;
  align-items: center;
`;

const WalletSection = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 420px;
  flex-direction: column;
  background-color: #ff5a00;
  align-items: center;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;

  .close-wrap {
    position: absolute;
    left: 90%;
    cursor: pointer;
  }

  h1 {
    margin-top: 50px;
  }

  h2 {
    margin-top: 40px;
  }
`;

const QRArea = styled.div`
  width: 200px;
  height: 200px;
  background-color: white;
`;

const CyclubWrap = styled.div`
  margin-top: 10px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    font-size: 18px;
    font-weight: bold;
    color: #ff5a00;
  }
`;

const CyclubBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 320px;
  width: 100%;
  height: 70px;
  margin: 15px 0 0;
  background-color: #ff5a00;
  border: 0;
  border-radius: 10px;
  color: white;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.40625rem;
  outline: none;
  cursor: pointer;
  box-sizing: border-box;
`;

const LogoImg = styled.img`
  height: 25px;
  @media (min-width: 768px) {
    height: 30px;
  }
`;
