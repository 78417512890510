import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
const { persistAtom } = recoilPersist();

// export const { persistAtom } = recoilPersist({
//   key: "foxData",
//   default: "",
//   effects_UNSTABLE: [persistAtom],
// });

interface IFoxData {
  rarity: string;
  trait_type: string;
  value: string;
}

const foxDataAtom = atom<IFoxData[]>({
  key: "foxData",
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export default foxDataAtom;
