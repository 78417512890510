import * as React from "react";

// pages
import Intro from "../../components/Intro";
import IronWrap from "../../components/IronWrap";
import Story from "../../components/Story";

// style
import { Container } from "../../components/StyledSystem/Container";

// icons
import { FaChevronUp } from "react-icons/fa";

interface IHome {}

const Home: React.FunctionComponent<IHome> = (props) => {
  // const [modalIsOpen, setModalIsOpen] = React.useState<boolean>(false);

  // const closeModal = () => {
  //   setModalIsOpen(false);
  // };

  // React.useEffect(() => {
  //   setModalIsOpen(true);
  // }, []);

  // const openTwitter = React.useCallback(() => {
  //   window.open("https://twitter.com/BADFOXCLUB");
  // }, []);

  // const openDiscord = React.useCallback(() => {
  //   window.open("https://discord.gg/auZvqnQ4");
  // }, []);

  // const openWhitePaper = React.useCallback(() => {
  //   window.open(
  //     "https://badfoxclub.s3.ap-northeast-2.amazonaws.com/Whitepapers/BAD_FOX_CLUB_light+paper.pdf"
  //   );
  // }, []);

  return (
    <>
      <Intro />
      <Story />
      <IronWrap />
      <Container
        position="fixed"
        right={20}
        bottom={20}
        width={30}
        height={30}
        backgroundColor="#ff5a00"
        borderRadius={2.5}
      >
        <FaChevronUp
          style={{ cursor: "pointer" }}
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          size={30}
        />
      </Container>

      {/* <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={modalStyles}
        ariaHideApp={false}
      >
        <CloseBtn position={'absolute'} left={'90%'} onClick={closeModal}>
          <AiOutlineClose size={20} color={'white'} />
        </CloseBtn>
        <Container
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          justifyContent={'center'}
          position={'relative'}
          marginTop={40}
        >
          <Container>
            <img
              src='/images/popup-image.png'
              alt='comingsoon'
              style={{
                width: 290,
                paddingLeft: 15,
                paddingRight: 15,
              }}
            />
          </Container>
          <WhitePaperBtn
            width={'100%'}
            height={24}
            backgroundColor={'#2D2D2D'}
            borderRadius={5}
            border={'none'}
            marginTop={10}
            color={'white'}
            onClick={openWhitePaper}
          >
            WHITE PAPER 보러가기
          </WhitePaperBtn>
          <SocialWrap
            width={'100%'}
            display={'flex'}
            justifyContent={'center'}
            marginTop={10}
          >
            <SocialBtn
              borderRadius={5}
              border={'none'}
              backgroundColor={'transparent'}
              onClick={openTwitter}
            >
              <img src='/images/TWITTER.png' alt='twitter' />
            </SocialBtn>
            <SocialBtn
              borderRadius={5}
              border={'none'}
              backgroundColor={'transparent'}
              onClick={openDiscord}
            >
              <img src='/images/DISCORD.png' alt='discord' />
            </SocialBtn>
          </SocialWrap>
        </Container>
      </Modal> */}
    </>
  );
};

export default Home;
