import * as React from "react";
import { BrowserRouter } from "react-router-dom";
import About from "./About";
import Faq from "./Faq";
import Footer from "./Footer";
import PreBooking from "./PreBooking";
import RoadMap from "./Roadmap";
import { Button } from "./StyledSystem/Button";
import { Container } from "./StyledSystem/Container";
import { Desc } from "./StyledSystem/Description";
import { Title } from "./StyledSystem/Title";
import Team from "./Team";

interface IIronWrapProps {}

const IronWrap: React.FunctionComponent<IIronWrapProps> = (props) => {
  const moveScroll = React.useRef();
  const ref = React.useRef();

  return (
    <>
      <Container
      // backgroundImage={[
      //   "url('/images/Iron-mobile-bg.png')",
      //   "url('/images/Iron-bg.png')",
      // ]}
      // backgroundRepeat="no-repeat"
      // backgroundSize="cover"
      // backgroundPosition="center"
      // width="100%"
      >
        <About />
        {/* <RoadMap /> */}
        {/* <Team /> */}
        <Faq />
        {/* <PreBooking /> */}
        <Footer />
      </Container>
    </>
  );
};

export default IronWrap;
