import styled from "styled-components";

import {
  color,
  ColorProps,
  space,
  position,
  PositionProps,
  typography,
  TypographyProps,
  SpaceProps,
  layout,
  LayoutProps,
} from "styled-system";

interface Props
  extends ColorProps,
    TypographyProps,
    SpaceProps,
    PositionProps,
    LayoutProps {}

export const Desc = styled.p<Props>`
  ${color}
  ${typography}
  ${space}
  ${position}
  ${layout}
`;
