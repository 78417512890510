// styled-system
import { Container } from "../StyledSystem/Container";
// icon
import FoxList from "./FoxList";
import { FaChevronUp } from "react-icons/fa";

const Right = () => (
  <Container color="#fff" width="100%">
    <Container mb="7rem">
      <FoxList />
      <Container
        position="fixed"
        right={20}
        bottom={20}
        width={30}
        height={30}
        backgroundColor="#ff5a00"
        borderRadius={5}
        color="#000"
      >
        <FaChevronUp
          style={{ cursor: "pointer" }}
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          size={30}
        />
      </Container>
    </Container>
  </Container>
);

export default Right;
