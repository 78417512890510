import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  @font-face {
      font-family: 'Pretendard';
      src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
      font-weight: 400;
      font-style: normal;
  }
  * {
    box-sizing: border-box;
    body{
      font-family: Pretendard;
      margin: 0;
    }

    iframe {
      display: none;
    }
  }
`;

export default GlobalStyle;
