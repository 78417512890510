import styled from 'styled-components';

import {
  borders,
  flexbox,
  layout,
  space,
  background,
  position,
  PositionProps,
  BorderProps,
  color,
  ColorProps,
  FlexboxProps,
  LayoutProps,
  SpaceProps,
  BackgroundProps,
  typography,
  TypographyProps,
  shadow,
  ShadowProps,
} from 'styled-system';

interface Props
  extends BorderProps,
    ColorProps,
    FlexboxProps,
    LayoutProps,
    SpaceProps,
    BackgroundProps,
    TypographyProps,
    PositionProps,
    ShadowProps {}

export const Button = styled.button<Props>`
  ${borders}
  ${flexbox}
  ${layout}
  ${space}
  ${background}
  ${typography}
  ${color}
  ${position}
  ${shadow}
`;
