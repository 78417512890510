import * as React from "react";
import { useTranslation } from "react-i18next";
import FaqItem from "./FaqItem";
import { Container } from "./StyledSystem/Container";

interface IFaqProps {}

const Faq: React.FunctionComponent<IFaqProps> = () => {
  const { t } = useTranslation();

  return (
    <>
      <Container
        backgroundImage={[
          "url('/images/Iron-mobile-bg.png')",
          "url('/images/Iron-bg.png')",
        ]}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        backgroundPosition="center"
        width="100%"
      >
        <Container
          id="FAQ"
          // backgroundImage={[
          //   "url('/images/about-blur-bg.png')",
          //   "url('/images/about-blur-mobile-bg.png')",
          // ]}
          backgroundColor="rgba(0,0,0,0.6)"
          backgroundRepeat="no-repeat"
          backgroundSize="cover"
          backgroundPosition="center"
          width="100%"
          pt={[120, 30]}
          paddingBottom={210}
          // height={'100vh'}
        >
          <Container
            m={[0, "auto"]}
            p={[0, 50]}
            maxWidth={1240}
            display="flex"
            flexDirection="column"
            alignItems="center"
            height="100%"
          >
            <Container
              display={["none", "flex"]}
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <img src="/images/face.png" alt="face" />
              <img src="/images/faq-logo.png" alt="logo" />
            </Container>
            <Container display={["block", "none"]}>
              <img src="/images/faq-mobile-logo.png" alt="logo" />
            </Container>

            <Container
              marginTop={30}
              lineHeight={1.6}
              maxWidth={1240}
              width="100%"
            >
              {(
                t("faq", { returnObjects: true }) as Array<{
                  question: string;
                  answer: string;
                }>
              ).map((item, i) => (
                <FaqItem
                  key={i}
                  id={i + 1}
                  title={item.question}
                  content={item.answer}
                />
              ))}
            </Container>
          </Container>
        </Container>
      </Container>
    </>
  );
};

export default Faq;
