import React from "react";
//styled-system
import { Container } from "./StyledSystem/Container";
//img
import Left from "./Members/Left";
import Right from "./Members/Right";

const Members = () => {
  return (
    <Container
      pt={78}
      width="100%"
      backgroundColor="#000"
      height="100%"
      pb={[0, "35rem"]}
    >
      <Container display="flex" px={[0, 30]} flexDirection={["column", "row"]}>
        <Left />
        <Right />
      </Container>
    </Container>
  );
};

export default Members;
