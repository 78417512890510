import React from "react";
import Home from "./pages/Home";
import Providers from "./providers/Providers";
import { RecoilRoot } from "recoil";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Members from "./components/Members";
import Header from "./components/Header";
import About from "./components/About";
import Faq from "./components/Faq";
import Story from "./components/Story";

function App() {
  return (
    <BrowserRouter>
      <RecoilRoot>
        <Providers>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/story" element={<Story />} />
            <Route path="/members" element={<Members />} />
            <Route path="/about" element={<About />} />
            <Route path="/FAQ" element={<Faq />} />
          </Routes>
        </Providers>
      </RecoilRoot>
    </BrowserRouter>
  );
}

export default App;
