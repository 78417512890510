import * as React from "react";
import styled from "styled-components";

// styled-system
import { Desc } from "./StyledSystem/Description";
import { Button } from "./StyledSystem/Button";
import { Container } from "./StyledSystem/Container";

interface IFooterProps {}

const Footer: React.FunctionComponent<IFooterProps> = (props) => {
  // const onClickWhitePaper = React.useCallback(() => {
  //   window.open(
  //     "https://badfoxclub.s3.ap-northeast-2.amazonaws.com/Whitepapers/BAD_FOX_CLUB_light+paper.pdf"
  //   );
  // }, []);

  return (
    <>
      <Container
        backgroundImage={[
          "url('/images/Iron-mobile-bg.png')",
          "url('/images/Iron-bg.png')",
        ]}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        backgroundPosition="center"
        width="100%"
      >
        <Container
          backgroundColor="rgba(11, 11, 11, 0.75)"
          width="100%"
          // height={126}
        >
          <Container
            m={[0, "auto"]}
            p={[0, 16]}
            maxWidth={1240}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100%"
          >
            <Container marginTop={26}>
              <picture>
                <source
                  media="(min-width:769px)"
                  srcSet="/images/footer-logo.png"
                />
                <img src="/images/footer-mobile-logo.png" alt="logo" />
              </picture>
            </Container>

            <Desc
              color="rgba(255, 229, 218, 0.4)"
              mt={[10, 20]}
              mb={2}
              fontSize={[14, 20]}
            >
              copyrightⓒ badfoxclub All rights reserved
            </Desc>
            <Desc mt={0} color="rgba(255, 229, 218, 0.4)" fontSize={[14, 20]}>
              e-mail : badfoxclub@gmail.com
            </Desc>
            {/* <WhitePaperBtn
            width={160}
            height={36}
            borderRadius={5}
            backgroundColor={'transparent'}
            border={'solid 1px rgba(255, 229, 218, 0.4)'}
            color={'rgba(255, 229, 218, 0.4)'}
            marginBottom={23}
            onClick={onClickWhitePaper}
          >
            White Paper
          </WhitePaperBtn> */}
          </Container>
        </Container>
      </Container>
    </>
  );
};

export default Footer;

const WhitePaperBtn = styled(Button)`
  cursor: pointer;
`;
