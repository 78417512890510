import * as React from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";

// langchage
import { useTranslation } from "react-i18next";

// parallax
import { Parallax } from "react-scroll-parallax";

// styled-system
import { Desc } from "./StyledSystem/Description";
import { Title } from "./StyledSystem/Title";
import { Button } from "./StyledSystem/Button";
import { Container } from "./StyledSystem/Container";

// icon
import { AiOutlineClose } from "react-icons/ai";

interface IAboutProps {}

const About: React.FunctionComponent<IAboutProps> = (props) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  // const onClickOpen = React.useCallback(() => {
  //   window.document.body.style.overflow = "hidden";
  //   setIsOpen(true);
  // }, [isOpen]);
  const onClickClose = React.useCallback(() => {
    window.document.body.style.overflow = "overlay";
    setIsOpen(false);
  }, []);

  const navigate = useNavigate();
  return (
    <>
      {isOpen && (
        <AboutModal>
          <AboutDimmed onClick={onClickClose} />
          <ContainerGroup>
            <ContainerArea>
              <WalletSection>
                <WalletContainer onClick={onClickClose}>
                  <AiOutlineClose size="20px" />
                </WalletContainer>

                <Title
                  color="#000"
                  fontSize={[13, 20]}
                  fontWeight="bold"
                  pb="5px"
                  pt={[37, 25]}
                >
                  Coming soon
                </Title>
              </WalletSection>
            </ContainerArea>
          </ContainerGroup>
        </AboutModal>
      )}

      <Container
        backgroundImage={[
          "url('/images/Iron-mobile-bg.png')",
          "url('/images/Iron-bg.png')",
        ]}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        backgroundPosition="center"
        width="100%"
      >
        <Container
          id="ABOUT"
          pt={["150px", "15px"]}
          backgroundColor="rgba(0,0,0,0.6)"
          backgroundImage={[
            "url('/images/about-blur-bg.png')",
            "url('/images/about-blur-mobile-bg.png')",
          ]}
          backgroundRepeat="no-repeat"
          backgroundSize="cover"
          backgroundPosition="center"
          width="100%"
          minHeight="100vh"
          position="relative"
        >
          <Parallax speed={20}>
            <Container
              position="absolute"
              zIndex={-1}
              backgroundImage={[
                "url('/images/about-stamp-mobile.png')",
                "url('/images/about-stamp.png')",
              ]}
              // top={'-25%'}
              backgroundRepeat="no-repeat"
              backgroundSize="cover"
              backgroundPosition="center"
              width="100%"
              height={[450, "100%"]}
              // height={600}
            />
            <Container
              m={[0, "auto"]}
              p={[0, 16]}
              maxWidth={1240}
              display="flex"
              flexDirection="column"
              alignItems="center"
              height="100%"
            >
              <Container
                display={["none", "flex"]}
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                marginTop={50}
              >
                <img src="/images/face.png" alt="face" />
                <img src="/images/about-logo.png" alt="logo" />
              </Container>
              <Container display={["block", "none"]}>
                <img src="/images/about-mobile-logo.png" alt="logo" />
              </Container>
              {/* <Container marginTop={50}>
              <picture>
                <source
                  media='(min-width:769px)'
                  srcSet='/images/about-logo.png'
                />
                <img src='/images/about-mobile-logo.png' alt='logo' />
              </picture>
            </Container> */}
              <Container
                display="flex"
                flexDirection={["column", "row"]}
                justifyContent="center"
                alignItems={["center"]}
              >
                <Container marginTop={[0, 20]} textAlign="center">
                  <picture>
                    <source
                      media="(min-width:769px)"
                      srcSet="/images/about-wanted.png"
                    />
                    <IMGItem src="/images/about-mobile-wanted.png" alt="logo" />
                  </picture>
                </Container>
                <Container pt={[10, 0]} margin="auto">
                  <Container
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    textAlign={["center", "left"]}
                  >
                    <Desc
                      color="white"
                      fontWeight="bold"
                      /*marginTop={53}*/ fontSize={[14, 18.5]}
                      letterSpacing="0.5px"
                    >
                      {t("about.copy1")}
                    </Desc>
                    <Copy color="white" lineHeight={1.8} fontSize={[13, 15]}>
                      {t("about.copy2")}
                    </Copy>
                    <Copy color="white" lineHeight={1.8} fontSize={[13, 15]}>
                      {t("about.copy3")}
                    </Copy>
                    <Link to="/members">
                      <Container
                        display="flex"
                        alignItems="center"
                        justifyContent={["center", "left"]}
                      >
                        <CustomButton
                          width={280}
                          marginTop={4}
                          py={3}
                          borderRadius={9}
                          border="1px solid #fff"
                          backgroundColor="rgba(0,0,0,0.2)"
                          color="#fff"
                          fontSize={14}
                          onClick={() => navigate("/members")}
                        >
                          {t("about.button")}
                        </CustomButton>
                      </Container>
                    </Link>
                    <Container></Container>
                  </Container>
                </Container>
              </Container>
            </Container>
          </Parallax>
        </Container>
      </Container>
    </>
  );
};

export default About;

const Copy = styled(Desc)`
  white-space: pre-line;
`;

const CustomButton = styled(Button)`
  cursor: pointer;

  &:hover {
    background-color: #ffffff5b;
    color: #000;
  }
`;

const AboutModal = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
`;

const AboutDimmed = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
`;
const ContainerGroup = styled.div`
  position: absolute;
  inset: 55% auto auto 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 35%;
  /* width: 100vw;
  display: flex; */
  @media (min-width: 768px) {
  }
`;

const ContainerArea = styled.div`
  display: flex;
  outline: none;
  border-radius: 16px;
  box-shadow: rgb(0 0 0 / 25%) 0px 4px 4px 0px;
  background-color: #ffffff;

  @media (min-width: 768px) {
    min-width: 300px;
  }
`;

const WalletSection = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100px;
  flex-direction: column;
  background-color: #fff;
  align-items: center;
  border-radius: 20px;
`;

const IMGItem = styled.img`
  width: 100%;
  @media (min-width: 769px) {
    width: 90%;
  } ;
`;

const WalletContainer = styled(Container)`
  position: absolute;
  top: 13px;
  right: 13px;
  cursor: pointer;
`;
