import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
const { persistAtom } = recoilPersist();

const filterAscDescAtom = atom<"ASC" | "DESC">({
  key: "filterAscDesc",
  default: "ASC",
  effects_UNSTABLE: [persistAtom],
});

export default filterAscDescAtom;
