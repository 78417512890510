import React from "react";
import styled from "styled-components";

// recoil
import { gridAtom } from "../../atoms/grid";
import { useRecoilState } from "recoil";
import searchKeywordAtom from "../../atoms/searchKeyword";
import filterAscDescAtom from "../../atoms/filterAscDesc";
import { rarityChkboxAtom } from "../../atoms/rarityChkbox";

// img
import { filter } from "../../assets/img/index";

// styled-system
import { Container } from "../StyledSystem/Container";
import { Title } from "../StyledSystem/Title";
import { Button } from "../StyledSystem/Button";

// icon
import { AiOutlineClose } from "react-icons/ai";
import GridViewIcon from "@mui/icons-material/GridView";
import GridOnIcon from "@mui/icons-material/GridOn";
import SearchIcon from "@mui/icons-material/Search";

const RadioBtns = [
  { id: 1, name: "오름차순", status: "ASC" },
  { id: 2, name: "내림차순", status: "DESC" },
];

const ChkBoxes = [
  {
    id: 1,
    label: "NORMAL",
    name: "normal",
  },
  {
    id: 2,
    label: "MIDDLE",
    name: "middle",
  },
  { id: 3, label: "RARE", name: "rare" },
  {
    id: 4,
    label: "SUPER RARE",
    name: "superRare",
  },
  {
    id: 5,
    label: "SPECIAL EDITION",
    name: "specialEd",
  },
];

interface ICheckbox {
  name?: string;
  checked?: boolean;
}
const Left = () => {
  const [currentGrid, setCurrentGrid] = useRecoilState(gridAtom);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [checks, setChecks] = useRecoilState(rarityChkboxAtom);
  const [radioSelected, setRadioSelected] = useRecoilState(filterAscDescAtom);
  const [search, setSearch] = useRecoilState(searchKeywordAtom);
  const [foxLists, setFoxLists] = React.useState([]);

  const onClickOpen = React.useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const handleClickClearFilter = React.useCallback(() => {
    setChecks({
      normal: false,
      middle: false,
      rare: false,
      superRare: false,
      specialEd: false,
    });
  }, []);
  // TODO: 함수 타입 추가 필요
  const handleChangeCheckbox = React.useCallback((e: any) => {
    const { name, checked } = e.target;
    setChecks((prev) => ({ ...prev, [name]: checked }));
  }, []);

  const handleChangeType: React.ChangeEventHandler<HTMLInputElement> = (
    e: any
  ) => {
    setSearch(e.target.value);
  };

  const handleChangeRadio = () => {
    setRadioSelected((prev) => (prev === "ASC" ? "DESC" : "ASC"));
  };

  const handleChangeSearch = (e: any) => {
    e.preventDefault();
    if (search === null || search === "") {
      //@ts-ignore
      return alert("닉네임을 검색창에 입력해주세요");
    } else {
      //@ts-ignore
      const filteredArray = foxLists.filter((list) => list.name === search);
      setFoxLists(filteredArray);
    }
  };

  return (
    <>
      {/* 모바일용 검색창 / 필터창 */}
      <Container display={["block", "none"]}>
        <Container
          color="#fff"
          px={16}
          flexDirection="column"
          width="100%"
          display="flex"
        >
          <Container
            display="flex"
            width="100%"
            alignItems="center"
            justifyContent="center"
          >
            <Container width="100%" pb="5px">
              <Container position="relative">
                <FormTag onSubmit={handleChangeSearch}>
                  <Search
                    type="text"
                    value={search}
                    onChange={handleChangeType}
                    placeholder="닉네임"
                  />
                  <SearchBtn type="submit" top="2.5px" left="2px">
                    <SearchIcon
                      style={{
                        color: "#ff5a00",
                        width: "21px",
                      }}
                    />
                  </SearchBtn>
                </FormTag>
              </Container>
            </Container>

            <Container onClick={onClickOpen} display={["block", "none"]}>
              <FilterBtn>
                <img src={filter} />
              </FilterBtn>
            </Container>
          </Container>

          {/* Grid Icons */}
          <Container display="flex" justifyContent="start" mb="5px">
            <Buttons
              className="active"
              backgroundColor="transparent"
              border="none"
              onClick={() => setCurrentGrid(1)}
            >
              <GridViewIcon
                style={{
                  fill: currentGrid === 1 ? "#ff5a00" : "grey",
                  width: "20px",
                }}
              />
            </Buttons>
            <Buttons
              backgroundColor="transparent"
              border="none"
              onClick={() => setCurrentGrid(2)}
            >
              <GridOnIcon
                style={{
                  fill: currentGrid === 2 ? "#ff5a00" : "grey",
                  width: "20px",
                }}
              />
            </Buttons>
          </Container>
        </Container>
      </Container>
      {isOpen && (
        <Container
          zIndex={9999}
          px={16}
          color="#fff"
          position="absolute"
          background="#000"
          width="100%"
          height="100vh"
        >
          <CloseBtnDiv
            position="absolute"
            top={-13}
            right={13}
            onClick={onClickOpen}
          >
            <AiOutlineClose size={25} color="#fff" cursor="pointer" />
          </CloseBtnDiv>
          <Container color="#fff">
            {/* BadFox # -  Radio Buttons */}
            <Container mb="20px">
              <Container
                display="flex"
                justifyContent="start"
                borderBottom="1px solid #fff"
                mb="10px"
              >
                <Title
                  fontWeight="bolder"
                  letterSpacing="1px"
                  fontSize={13}
                  lineHeight={2}
                  mb="4px"
                >
                  BAD FOX #
                </Title>
              </Container>
              {/* Radio Buttons */}
              <Container>
                {RadioBtns.map((RadioBtn, i: number) => (
                  <Radio
                    key={`mobile_category_${i}`}
                    htmlFor={`mobile_category_${RadioBtn.id}`}
                  >
                    <span className="title">{RadioBtn.name}</span>
                    <input
                      type="radio"
                      id={`mobile_category_${RadioBtn.id}`}
                      name="mobile_category"
                      checked={radioSelected === RadioBtn.status}
                      onChange={handleChangeRadio}
                      // onClick={() => handleChangeRadio(RadioBtn.status)}
                    />
                    <span className="button" />
                  </Radio>
                ))}
              </Container>
            </Container>

            {/* Rarity - CheckBoxes */}
            <Container
              display="flex"
              justifyContent="start"
              borderBottom="1px solid #fff"
              mb="10px"
            >
              <Title
                fontWeight="bolder"
                letterSpacing="1px"
                fontSize={13}
                lineHeight={2}
                mb="4px"
              >
                RARITY
              </Title>
            </Container>
            {/* Clear Filter */}
            <Container display="flex" justifyContent="end">
              <Buttons
                color="#ff5a00"
                fontWeight="bolder"
                fontSize="9px"
                mt={0}
                mb="10px"
                border="none"
                backgroundColor="transparent"
                onClick={handleClickClearFilter}
              >
                CLEAR ALL FILTERS
              </Buttons>
            </Container>
            {/* Checkboxes */}
            <Container>
              {ChkBoxes.map((checkbox: any) => (
                <Checkbox key={checkbox.id} htmlFor={checkbox.name}>
                  <span className="title">{checkbox.label}</span>
                  <input
                    type="checkbox"
                    id={checkbox.name}
                    name={checkbox.name}
                    // @ts-ignore
                    checked={checks[checkbox.name]}
                    onChange={handleChangeCheckbox}
                  />
                  <span className="checkbox" />
                </Checkbox>
              ))}
            </Container>
          </Container>
        </Container>
      )}

      {/* PC용 검색창 / 필터창 */}
      <Container display={["none", "block"]}>
        <Container paddingRight={30} width="200px">
          {/* SearchBar & Grid Change */}
          <Container
            color="#fff"
            flexDirection="column"
            width="100%"
            display="flex"
          >
            <Container width="100%" pb="20px">
              <FormTag onSubmit={handleChangeSearch}>
                <Search
                  type="text"
                  value={search}
                  onChange={handleChangeType}
                  placeholder="닉네임"
                />
                <SearchBtn top="2px" right="2px" type="submit">
                  <SearchIcon
                    style={{
                      color: "#ff5a00",
                      width: "18px",
                    }}
                  />
                </SearchBtn>
              </FormTag>
              <Container display="flex" justifyContent="start">
                <Buttons
                  className="active"
                  backgroundColor="transparent"
                  border="none"
                  onClick={() => setCurrentGrid(1)}
                >
                  <GridViewIcon
                    style={{
                      fill: currentGrid === 1 ? "#ff5a00" : "grey",
                      width: "20px",
                    }}
                  />
                </Buttons>
                <Buttons
                  backgroundColor="transparent"
                  border="none"
                  onClick={() => setCurrentGrid(2)}
                >
                  <GridOnIcon
                    style={{
                      fill: currentGrid === 2 ? "#ff5a00" : "grey",
                      width: "20px",
                    }}
                  />
                </Buttons>
              </Container>
            </Container>
          </Container>

          {/* PC Radio & Checkboxes  */}
          <Container color="#fff">
            {/* BadFox # -  Radio Buttons */}
            <Container mb="20px">
              <Container
                display="flex"
                justifyContent="start"
                borderBottom="1px solid #fff"
                mb="10px"
              >
                <Title
                  fontWeight="bolder"
                  letterSpacing="1px"
                  fontSize={13}
                  lineHeight={2}
                  mb="4px"
                >
                  BAD FOX #
                </Title>
              </Container>
              {/* Radio Buttons */}
              <Container>
                {RadioBtns.map((RadioBtn, i: number) => (
                  <Radio
                    key={`pc_category_${i}`}
                    htmlFor={`pc_category_${RadioBtn.id}`}
                  >
                    <span className="title">{RadioBtn.name}</span>
                    <input
                      type="radio"
                      id={`pc_category_${RadioBtn.id}`}
                      name="pc_category"
                      checked={radioSelected === RadioBtn.status}
                      onChange={handleChangeRadio}
                    />
                    <span className="button" />
                  </Radio>
                ))}
              </Container>
            </Container>

            {/* Rarity - CheckBoxes */}
            <Container
              display="flex"
              justifyContent="start"
              borderBottom="1px solid #fff"
              mb="10px"
            >
              <Title
                fontWeight="bolder"
                letterSpacing="1px"
                fontSize={13}
                lineHeight={2}
                mb="4px"
              >
                RARITY
              </Title>
            </Container>
            {/* Clear Filter */}
            <Container display="flex" justifyContent="end">
              <Buttons
                color="#ff5a00"
                fontWeight="bolder"
                fontSize="9px"
                mt={0}
                mb="10px"
                border="none"
                backgroundColor="transparent"
                onClick={handleClickClearFilter}
              >
                CLEAR ALL FILTERS
              </Buttons>
            </Container>
            {/* Checkboxes */}
            <Container>
              {ChkBoxes.map((checkbox: any) => (
                <Checkbox key={checkbox.id} htmlFor={checkbox.name}>
                  <span className="title">{checkbox.label}</span>
                  <input
                    type="checkbox"
                    id={checkbox.name}
                    name={checkbox.name}
                    // @ts-ignore
                    checked={checks[checkbox.name]}
                    onChange={handleChangeCheckbox}
                  />
                  <span className="checkbox" />
                </Checkbox>
              ))}
            </Container>
          </Container>
        </Container>
      </Container>
    </>
  );
};

export default Left;

const FormTag = styled.form`
  position: relative;
`;

const SearchBtn = styled(Button)`
  background-color: transparent;
  border: none;
  position: absolute;
  cursor: pointer;
`;
const CloseBtnDiv = styled(Container)`
  cursor: pointer;
`;
const FilterBtn = styled(Button)`
  border: none;
  background-color: transparent;
  padding: 0 0 0 13px;
  cursor: pointer;
`;

const Search = styled.input`
  width: 100%;
  height: 31px;
  font-size: 12px;
  padding-left: 30px;
  background-color: transparent;
  border-radius: 5px;
  color: #fff;
  border: 2px solid #ff5a00;

  &:active,
  :focus {
    outline: none;
  }

  @media (min-width: 768px) {
    height: 30px;
    font-size: 11px;
    margin-bottom: 10px;
    padding-left: 10px;
  }
`;

const Buttons = styled(Button)`
  cursor: pointer;

  &.active {
    fill: #ff5a00;
  }
`;

const Radio = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  input {
    display: none;
  }
  .title {
    font-size: 12px;
    margin: 5px;
  }
  .button {
    content: "";
    width: 13px;
    height: 13px;
    background: black;
    border: 1px solid #ffffff5d;
    border-radius: 50%;
    cursor: pointer;
  }

  input:checked {
    & + .button {
      display: flex;
      align-items: center;
      justify-content: center;
      &:after {
        content: "";
        position: absolute;
        display: block;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: #ff5a00;
      }
    }
  }
`;

const Checkbox = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  input {
    display: none;
  }
  .title {
    font-size: 12px;
    margin: 5px;
  }
  .checkbox {
    content: "";
    width: 13px;
    height: 13px;
    background: black;
    border: 1px solid #ffffff5d;
    cursor: pointer;
    border-radius: 2px;
  }
  input:checked {
    & + .checkbox {
      display: flex;
      align-items: center;
      justify-content: center;
      &:after {
        content: "";
        position: absolute;
        top: 8.5px;
        right: 3px;
        display: block;
        width: 5px;
        height: 3px;
        border: 2px solid #ff5a00;
        border-top-style: none;
        border-right-style: none;
        transform: rotate(-45deg) scale(1, 1);
      }
    }
  }
`;
