import * as React from "react";
import styled from "styled-components";

// styled-system
import { Title } from "./StyledSystem/Title";
import { Button } from "./StyledSystem/Button";
import { Container } from "./StyledSystem/Container";

// icons
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

interface IFaqItemProps {
  id: number;
  title: string;
  content: string;
}

const FaqItem: React.FunctionComponent<IFaqItemProps> = (props) => {
  const [isActive, setIsActive] = React.useState<boolean>(false);

  const onClickQuestion = React.useCallback(() => {
    setIsActive((prev) => !prev);
  }, []);

  return (
    <>
      <FaqWrap
        display="flex"
        marginTop={10}
        flexDirection="column"
        width="100%"
        onClick={onClickQuestion}
        paddingTop={10}
        paddingRight={["20px", 0]}
        paddingLeft={["20px", 0]}
      >
        <Container
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          borderBottom="1px solid #fff"
          position="relative"
        >
          <Title
            display="flex"
            color="white"
            fontSize={[13, 16]}
            width={["100%", 600]}
            fontWeight={["normal", "bold"]}
          >
            {props.title}
          </Title>
          <Button border="none" margin={0} backgroundColor="transparent">
            {isActive ? (
              <RemoveIcon style={{ color: "fff" }} />
            ) : (
              <AddIcon style={{ color: "fff" }} />
            )}
          </Button>
        </Container>
        {isActive && (
          <Container display="flex" alignItems="center">
            <Title
              color="#ff5a00"
              fontSize={[12, 16]}
              width={[310, 600]}
              fontWeight={["normal", "bold"]}
            >
              {props.content}
            </Title>
          </Container>
        )}
      </FaqWrap>
    </>
  );
};

export default FaqItem;

const FaqWrap = styled(Container)`
  cursor: pointer;
  white-space: pre-line;
`;
