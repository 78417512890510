import styled from "styled-components";

import {
  BorderProps,
  color,
  ColorProps,
  space,
  position,
  PositionProps,
  typography,
  TypographyProps,
  SpaceProps,
  layout,
  LayoutProps,
  borders,
} from "styled-system";

interface Props
  extends BorderProps,
    ColorProps,
    TypographyProps,
    SpaceProps,
    PositionProps,
    LayoutProps {}

export const Title = styled.h1<Props>`
  ${borders}
  ${color}
  ${typography}
  ${space}
  ${position}
  ${layout}
`;
