import { atom } from "recoil";

interface ICheck {
  normal?: boolean;
  middle?: boolean;
  rare?: boolean;
  superRare?: boolean;
  specialEd?: boolean;
}

export const rarityChkboxAtom = atom<ICheck>({
  key: "rarityChkbox",
  default: {
    normal: false,
    middle: false,
    rare: false,
    superRare: false,
    specialEd: false,
  },
});
